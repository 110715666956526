body {
    text-rendering: optimizeLegibility;    
}

/* Wordpress */
#wpadminbar { top: 0 !important; }

.deviceClass { float: left; }
.inner-wrapper { margin: 0 auto; max-width: 1050px; padding: 0 20px; }

.top-nav { }

.header-small { }

body.down .has-wp-bar { top: 32px; }

.header-wrapper { }
.nav-wrapper { }

/* Navigation */
.toggle-menu { display: none; }
.expand-nav { clear: none; width: auto; } 
.nav { width: auto; }
.nav > li {
    border-top: 0;
    clear: none;
    float: left;
    padding: 0;
    text-align: center;
    width: auto;
}

.nav > li > .parent i {
    bottom: -28px;
    left: 0;
    margin: auto;
    right: 0;
    top: auto;
}
.nav > li > .parent:hover i,
.nav > li.hover > .parent i {
	-webkit-transform: rotate(0deg);
	-ms-transform: rotate(0deg);
	transform: rotate(0deg);
}

.nav li li {
  &:first-child { 
      border-top: 0;
    } 
    
    a { padding: 10px; }
}

.nav > li li .parent i {
    right: 0;
    top: 0;
	-webkit-transform: rotate(-90deg);
	-ms-transform: rotate(-90deg);
	transform: rotate(-90deg);
}
.nav > li li .parent:hover i,
.nav > li li.hover .parent i {
	-webkit-transform: rotate(-90deg);
	-ms-transform: rotate(-90deg);
	transform: rotate(-90deg);
}

.nav > li.hover > a {  }

.nav > li.hover,
.nav li.current_page_item,
.nav li.current_page_parent,
.nav li.current-page-ancestor {

}

.nav > li.hover a,
.nav .current_page_item a,
.nav .current_page_parent a,
.nav .current-page-ancestor a {
	
}
.nav > li.hover > ul {
  left: 0;
  right: 0;
  top: 45px;
  position: absolute;
  width: 200px;
}
.nav li ul li {
  float: left;
  margin: 0;
  text-align: left;
}
.nav li li.hover ul {
    left: 100%;
    top: 0;
    position: absolute;
    width: 200px;
}
.nav li li.hover ul li {
	text-align: left;
}

/* Main wrapper
-------------------------------------------------------------------------------*/
.wrapper .sidebar {  }
.main-wrapper { } 

/* Columns
-------------------------------------------------------------------------------*/
.wrapper { }

.form-col {
    margin-top: 0;
    width: 48%; 
    
    &:nth-child(even) { float: right; margin-top: 0; }    
    &:nth-child(odd) { clear: left; }
}

input[type="submit"],
input[type="reset"],
input[type="button"],
button,
.btn,
.tribe-events-read-more { width: auto; }

/* Top footer
-------------------------------------------------------------------------------*/
.top-footer { }

/* Lower footer
-------------------------------------------------------------------------------*/
.footer-wrapper {  }

.ee { margin-top: 59px; }


/* Additional media queries
-------------------------------------------------------------------------------*/
@media (min-width: 1360px) {
    
}

@media (max-width: 1300px) {
    
}

@media (max-width: 1100px) {
    
}

@media (max-width: 900px) {
    
}

@media (max-width: 800px) {
    
}
