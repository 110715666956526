/* Define any properties or classes to be used in CSS here */

@sans-serif: "Open Sans", Helmet, Freesans, sans-serif;
@serif: Georgia, Times, Times New Roman, serif;

@text-colour: #333;

@red: #d43b55;
@dark-red: #a12338;
@bright-red: #ff4766;
@navy: #23408f;
@bright-navy: #1b48c2;
@50percent-navy: rgba(35,64,143,0.5);
@10percent-navy: rgba(35,64,143,0.1);
@blue: #0085cc;
@bright-blue: #00a6ff;
@light-blue: #bce7fe;

@text-colour: #333;
@dark-grey: #404040;
@70percent-dark-grey: rgba(64,64,64,0.7);
@50percent-dark-grey: rgba(64,64,64,0.5);
@mid-grey: #666;
@table-grey: #e4e4e4;
@white: #fff;

@light-grey: #e3e5eb;
@4per: 6.25%;

.clearfix:after,.clearfix:before{content:" ";display:block;height:0;overflow:hidden}
.clearfix:after{clear:both}
.clearfix{zoom:1}

.hide,
.sr-only {
  position: absolute !important;
  top: -9999px !important;
  left: -9999px !important;
}
.unhide {
    position: relative !important;
    top: 0 !important;
    left: 0 !important;
}

.full-width-container {
    clear: left; 
	float: left; 
	position: relative; 
	width: 100%;
	.clearfix;
}

.box-shadow {
    box-shadow: 1px 1px 1px rgba(0,0,0,0.1), 
        	    1px 1px 1px rgba(255,255,255,0.1) inset;    
}

.text-shadow {
    text-shadow: 1px 1px 1px rgba(0,0,0,0.3);    
}

.ltr-blue-gradient {
    background: @blue;
    background: linear-gradient(to right, @blue 0%, @navy 100%);
}